import httpCommon from '../axios'
import useAuth from './useAuth'

const useRefreshToken = () => {
    const {setAuth} = useAuth();

    const refresh = async () => {
        const response = await httpCommon.post('/company/refresh');
        const roles = [1000, 2000, 3000];
        const { access_token, user, user: {is_admin}  } = response?.data?.data;
        setAuth({ access_token, user, roles, is_admin });

        return access_token;
    }
    return refresh;
};

export default useRefreshToken
